.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar-header {
  background-color: @sidebar-background;
}

.navbar-brand {
  float: none;
  padding: 0;
  padding-top: 28px;
  display: block;
  height: (@navbar-height + 10);

  & > img {
    margin: 0 auto;
  }
}

.navbar-nav > li {
  & > a {
    padding-bottom: 6px;
    padding-top: 36px;
    border-bottom: 8px solid #222;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: 400;
    bottom: -8px;

    &:hover, &:focus {
      border-bottom-color: @color-red;
    }
  }
}

.navbar-default {
  border-width: 0;
  border-bottom-width: 8px;
  border-bottom-color: #222;
  margin-top: -8px;
  top: -8px;

  & .navbar-nav > .active > a,
  & .navbar-nav > .active > a:hover,
  & .navbar-nav > .active > a:focus {
    border-bottom-color: @color-red;
  }
}