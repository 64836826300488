html, body {
  height: 100%;
}

hr {
  margin: 6px 0;
}

ul ul,
ol ul,
ul ol,
ol ol {
  padding-left: 20px;
}

.row-spacer {
  height: 28px;
  width: 100%;
  clear: both;
}

/* site wrapper for full height layout */
.site-wrapper {
  background-color: #222;
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.site-wrapper-inner {
  background-color: #fff;
}

/* equal height columns */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}