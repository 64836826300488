.table-ingenitron {}

.table-ingenitron-datasheet {
  font-size: 9.5pt;

  /* table header */
  > thead > tr {
    &.datasheet-item-title > th {}
    &.datasheet-item-description > th {
      vertical-align: top;
      font-weight: 600;
    }
    &.datasheet-item-image > th {
      vertical-align: middle;
    }
    &.datasheet-item-download > th {}

    &.datasheet-item-image > th,
    &.datasheet-item-download > th {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &:last-child > th:first-child {
      border-bottom-color: @color-grey;
    }

    & > th {
      font-weight: 500;
      text-align: center;
      border: 1px solid #fff;
      border-bottom-color: @color-grey;
      border-right-color: @color-grey;

      &:first-child {
        border-color: #fff;
        border-right-color: @color-grey;
      }
    }
  }

  /* table body */
  > tbody > tr {
    & > td {
      border: 1px solid #fff;
      border-right-color: @color-grey;
      border-bottom-color: @color-grey;

      &:first-child {
        background-color: @color-grey;
        border-bottom-color: #ffffff;
        font-weight: 500;
      }
    }
  }

  /* header columns */
  .header-col0, .header-col1, .header-col2, .header-col3, .header-col4 {
    color: #f4f4f4;
    & > a {
      color: #f4f4f4;
      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .header-col0 {
    background-color: @color-red;
  }

  .header-col1 {
    background-color: @color-red-dark5;
  }

  .header-col2 {
    background-color: @color-red-dark10;
  }

  .header-col3 {
    background-color: @color-red-dark15;
  }

  .header-col4 {
    background-color: @color-red-dark20;
  }
}