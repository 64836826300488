//==
//
a.lightboxable {
  position: relative;
  display: inline-block;

  &:after {
    font-family: FontAwesome;
    font-size: 18px;
    font-weight: 400;
    content: @fa-var-search-plus;
    color: rgba(255, 255, 255, .4);
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    padding: 0px 5px 1px 5px;
    bottom: 0px;
    left: 0px;
  }

  &:hover {
    &:after {
      color: rgba(255, 255, 255, .88);
      background-color: rgba(255, 255, 255, .35);
    }
  }

  img {}
}

//== Tiles
//
.tile-ingenitron {
  border-top: 1px solid transparent;
  border-bottom: 1px solid darken(@sidebar-background, 5%);
  width: 90%;
  min-height: 250px;
  margin: 14px auto;

  &.tile-ingenitron-small {
    min-height: 170px;
  }

  h2 {
    margin: 10px 0 12px 0;
    span {
      position: relative;
    }
  }

  img {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover {
    border-bottom-color: @brand-primary;
    border-top: 1px solid @brand-primary;
    cursor: pointer;

    h2 {
      span {
        &:before {
          font-family: FontAwesome;
          font-size: 5px;
          color: darken(@sidebar-nav-subitem-background, 8%);
          content: "\f0c8";
          position: absolute;
          bottom: 3px;
          left: -7px;
          color: @brand-primary;
        }
      }
    }

    img {
      -moz-transform: scale(1.12);
      -webkit-transform: scale(1.12);
      transform: scale(1.12);
    }
  }
}

.tile-description {
  width: 92%;
  margin: 10px auto 7px auto;
  font-size: 13px;
}


//== lists
//
.dl-ingenitron {
  dt {
    font-size: 13px;
    width: 170px;
  }
  dd {
    margin-left: 185px;
  }
}

ul {
  &.list-ingenitron {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      position: relative;
      list-style: none;
      &:hover {
        &:before {
          //color: @brand-primary;
        }
      }
    }

    li:before {
      font-family: 'FontAwesome';
      font-size: 5px;
      //color: darken(@sidebar-nav-subitem-background, 8%);
      color: @brand-primary;
      content: "\f0c8";
      position: absolute;
      left: -10px;
      top: 9px;
    }
  }
}

//==
//
.citation {
  background-color: #fff;

  .jumbotron {
    background-color: #fff;
    &.jumbotron-ingenitron {
      strong {
        font-size: 16px;
      }
      small {
        float: right;
      }
    }
  }
}

.citation-effect {
  position: relative;

  &:after {
    -webkit-box-shadow: 5px 5px 8px rgba(0,0,0,0.05);
    -moz-box-shadow: 5px 5px 8px rgba(0,0,0,0.05);
    box-shadow: 5px 5px 8px rgba(0,0,0,0.05);
    position: absolute;
    content: "--";
    color: transparent;
    right: 0;
    bottom: 0;
    width: 22%;
    height: 50%;
  }

  &:before {
    -webkit-box-shadow: -5px -5px 8px rgba(0,0,0,0.05);
    -moz-box-shadow: -5px -5px 8px rgba(0,0,0,0.05);
    box-shadow: -5px -5px 8px rgba(0,0,0,0.05);
    position: absolute;
    content: "--";
    color: transparent;
    left: 0;
    top: 0;
    width: 22%;
    height: 50%;
  }
}

//==
//
.image-ingenitron {
  border: 1px solid rgba(0,0,0,.25);
}

