.mainRight {
  padding: 0px 6px 50px;

  h1 {
    margin-top:0;

    &.image {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 8px;
    }
  }

  h2, .h2 {
    font-size: 16px;
  }

  h1, h2 {
    &.image {
      a {
        display:block;

        & > span {
          display: none;
        }
      }
    }
  }

  hr {
    margin: 6px 0 6px 8px;
  }
}

.footer {
  color: @footer-link-color;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 1px;
  bottom:0;
  width:100%;

  .container-fluid {

    &.footer-top {
      background-color: #222;
      padding-top: 0;
      padding-bottom: 0;

      .container .row > div {
        padding: 26px 8px 22px 8px;
      }

      .sidebarLeft {
        background-color: #464849;
        min-height: 300px;
      }
    }

    &.footer-bottom {
      font-size: 11px;
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: darken(#464849, 5%);
    }
  }

  > a, a:link, a:visited, a:active, a:hover {
    font-family: @font-family-sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
    color: darken(@footer-link-color, 15%);
    text-decoration: none;

    &:hover {
      color: @footer-link-hover-color;
    }
  }

  ul {
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 0;

    & > li {
      line-height: 2.2;

      &.active a {
        color: @color-red;
      }
    }

    &.list-ingenitron {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      padding-left: 3px;

      li {
        &:before {
          color: rgba(223,223,223,.3);
        }
      }
    }
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    font-size: 16px;
    color: @color-red-dark10;
    margin-left: 13px;
  }
}

.lead {
  &.lead-small {
    font-size: 18px;
  }
}

.clickGoTo {
  &:hover {
    cursor: pointer;
  }
}

.userDropdown {
  position: absolute;
  right: 20px;
  top: 0;
}