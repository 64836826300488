.sidebarLeft {
  background-color: @sidebar-background;
  min-height: 640px;

  &.col-sm-5 {
    padding-left: -8px!important;
    padding-right: -8px!important;
  }

  ul {
    margin: 0 -8px;

    li {

      a {
        font-size: 8.6pt;
        color: #454545;
        display: block;
        padding: 7px 5px 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(255, 255, 255, 0.7);
        text-shadow: 1px 1px 0px rgba(0,0,0,.1);

        &:hover {
          color: @brand-primary;
        }
      }

      & > ul {
        background-color: @sidebar-nav-subitem-background;
        margin: 0;
        list-style: outside none none;
        padding: 0;

        & > li {

          & > a {
            font-size: 8.4pt;
            color: rgba(0, 0, 0, 0.6);
            padding: 4px 3px;
            margin-left: 10px;
            border-bottom: 1px solid @sidebar-nav-subitem-background;
            border-top: 1px solid @sidebar-nav-subitem-background;
            text-shadow: 0 0 0 rgba(0,0,0,0);
            position: relative;

            &:before {
              font-family: FontAwesome;
              font-size: 5px;
              color: darken(@sidebar-nav-subitem-background, 8%);
              content: "\f0c8";
              position: absolute;
              top: 9px;
              left: -4px;
            }

            &:hover,
            &.active {
              color: rgba(0, 0, 0, 1);

              &:before {
                color: @brand-primary;
              }
            }
          }

          & > ul li a {
            margin-left: 20px;
          }
        }
      }
    }
  }

  a, a:link, a:visited, a:active, a:hover {
    text-decoration: none;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  .locale-image {
    opacity:.45;

    &.active, &:hover {
      opacity:1;
    }
  }
}